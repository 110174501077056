import { useIsFocused } from '@react-navigation/native';
import DropDown from 'react-native-paper-dropdown';
import React, { useMemo } from 'react';
import {
  Dimensions,
  FlatList,
  ScrollView,
  StyleSheet,
  View,
} from 'react-native';
import { Card, Subheading, Text, TouchableRipple } from 'react-native-paper';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { ClassIDContext } from '../../lib/contexts';
import { ClassStatsProps } from '../../lib/navigation';
import {
  assessmentsByChildState,
  categoriesState,
  categoryOrderState,
  childrenByClassState,
  classState,
  classStatsState,
} from '../../store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CategoryChart } from '../category/CategoryChart';
import { icons } from '../icons';
import { useMultiFABScroll } from '../multifab/MultiFABContext';
import { RefreshUserData } from '../RefreshUserData';
import { ageToString } from '../../lib/calculate-age';
import { colors } from '../../theme';
import ChildInfo from '../child/ChildInfo';
import IconWrapper from '../ui/IconWrapper';
import { FadeInFromTopAnimation } from '../animations/FadeInFromTopAnimation';
import BouncingChevronRight from '../ui/BouncingChevronRight';
import usePressEffect from '../../hooks/use-press-effect';
import CircularProgress from '../ui/CircularProgress';
import RollOptions from '../ui/RollOptions';
import { TextInput } from 'react-native-paper';
import { showToast } from '../../lib/toast';
import { useDesktopStyles } from '../../hooks/use-desktop-styles';
import { isWeb } from '../../lib/is-web';
import DateSlider from '../ui/HistorySlider';
import { useAssessmentHistory } from '../../hooks/use-assessment-history';
const noop = () => {};

const percentageOptions = [
  { label: '20%', value: 20 },
  { label: '40%', value: 40 },
  { label: '60%', value: 60 },
  { label: '80%', value: 80 },
  { label: '100%', value: 100 },
];

export function ClassOverview({ navigation }: ClassStatsProps) {
  const isFocused = useIsFocused();
  const { setStatus, handleScroll } = useMultiFABScroll();
  const classId = React.useContext(ClassIDContext);
  const categories = useRecoilValue(categoriesState);
  const categoryOrder = useRecoilValue(categoryOrderState);

  const classroom = useRecoilValue(classState(classId));
  const children = classroom?.children || [];

  const { startDate, endDate, currentDate, setCurrentDate } =
    useAssessmentHistory(classId, true);

  const statsLoadable = useRecoilValueLoadable(
    classStatsState({ classId, date: currentDate }),
  );

  const { isDesktop, desktopContainerStyle } = useDesktopStyles();

  const [selectedChildPercentageFillRate, setSelectedChildPercentageFillRate] =
    React.useState(100);

  const [showDropdown, setShowDropdown] = React.useState(false);

  React.useEffect(() => {
    const loadPercentage = async () => {
      const savedPercentageChild = await AsyncStorage.getItem(
        'selectedPercentageChild',
      );
      if (savedPercentageChild) {
        setSelectedChildPercentageFillRate(parseInt(savedPercentageChild));
      }
    };

    loadPercentage();
  }, []);

  React.useEffect(() => {
    AsyncStorage.setItem(
      'selectedPercentageChild',
      selectedChildPercentageFillRate.toString(),
    );
  }, [selectedChildPercentageFillRate]);

  const { onCardPress, pressedCardIndex } = usePressEffect();

  const { averageAge = 0, categoryStats = {} } =
    statsLoadable.state === 'hasValue' ? statsLoadable.contents : {};

  const [showExtendedData, setShowExtendedData] = React.useState(false);

  const flatListRef = React.useRef<FlatList>(null);
  const onCategoryPress = React.useCallback((i: number) => {
    flatListRef.current?.scrollToIndex({ index: i + 1, animated: true });
  }, []);

  React.useEffect(() => {
    if (isFocused) {
      setStatus({ initial: { classId } });
    }
  }, [isFocused, setStatus, classId]);

  const renderItem = useMemo(
    () =>
      ({ item: categoryId, index }: { item: number; index: number }) => {
        if (categoryId === 0) {
          return (
            <FadeInFromTopAnimation delay={50}>
              <Card style={styles.card} onPress={noop}>
                {/* {!isWeb && (
                  <IconButton
                    style={styles.downloadButton}
                    rippleColor={colors.veryLightBlue}
                    iconColor={colors.darkRed}
                    icon="download"
                  />
                )} */}
                <Card.Content>
                  <View style={styles.overview}>
                    <FadeInFromTopAnimation delay={100}>
                      <View>
                        <Subheading style={styles.classroomLabel}>
                          {classroom?.label}{' '}
                        </Subheading>

                        <Text style={{ fontSize: 12, color: colors.grey }}>
                          Průměrný věk: {ageToString(averageAge)}
                        </Text>
                      </View>
                    </FadeInFromTopAnimation>
                    <View style={styles.itemContent}>
                      <ChildInfo length={children.length} />
                    </View>
                  </View>

                  <CategoryChart
                    {...{
                      onCategoryPress,
                      categoryOrder,
                      categoryStats,
                      childOrClass: classroom?.label,
                      type: 'class',
                    }}
                  />
                  <DateSlider
                    startDate={startDate}
                    endDate={endDate}
                    currentDate={currentDate}
                    onDateChange={setCurrentDate}
                  />
                </Card.Content>
              </Card>
            </FadeInFromTopAnimation>
          );
        }

        const item = categories[categoryId]!;
        const stats = categoryStats[categoryId]!;
        const lagging = stats?.laggingChildren || [];

        const deviceWidth = Dimensions.get('window').width;
        const calcWidth = (deviceWidth - 16 * 2 - 8 * 2) / 1.5;

        const averageFillRate = stats?.averageFillRate || 0;

        const notFilledOut = stats?.notFilledOutChildren.filter(
          (child) =>
            child.averageFillRateCategory * 100 <=
            selectedChildPercentageFillRate,
        );

        const reversePercentageAverageFillRate = Math.round(
          100 - averageFillRate * 100,
        );

        const Icon = icons[item.label];
        return (
          <Card
            style={[styles.card, { marginVertical: 16 }]}
            onPress={() =>
              onCardPress(index, true, () =>
                navigation.push('ClassCategory', {
                  classId,
                  categoryId: item.id,
                }),
              )
            }
          >
            <Card.Content style={{ gap: 12 }}>
              <View
                style={{
                  flexDirection: 'row',
                  marginBottom: 5,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  gap: 5,
                }}
              >
                <View
                  style={{
                    flexDirection: 'row-reverse',
                    alignItems: 'center',
                    gap: 6,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      flexShrink: 1,
                      width: calcWidth,
                    }}
                  >
                    {item.label}
                  </Text>
                  {Icon && (
                    <Icon style={{ width: 40, height: 40, marginRight: 5 }} />
                  )}
                </View>

                <BouncingChevronRight isPressed={pressedCardIndex === index} />
              </View>

              <View
                style={{
                  marginVertical: 6,
                }}
              >
                <IconWrapper
                  icon={'account-alert'}
                  color={colors.red}
                  bgColor={colors.veryLightRed}
                  width={35}
                  height={35}
                  iconSize={20}
                >
                  <View>
                    <Text style={{ marginTop: 5 }}>
                      Děti, které potřebují přidat (podpořit)
                    </Text>
                    <Text style={{ fontSize: 11, color: colors.grey }}>
                      Výkon pod rámcem očekávání
                    </Text>
                  </View>
                </IconWrapper>
              </View>

              <View>
                {lagging.map((child, idx) => (
                  <TouchableRipple
                    key={child.id}
                    rippleColor={
                      child.gender === 'M'
                        ? colors.veryLightBlue
                        : colors.veryLightDarkRed
                    }
                    onPress={() =>
                      onCardPress(child.id, true, () =>
                        navigation.push('ChildCategory', {
                          childId: child.id,
                          categoryId,
                        }),
                      )
                    }
                  >
                    <Card
                      style={[
                        styles.item,
                        child.gender === 'M' && styles.boy,
                        child.gender === 'F' && styles.girl,
                      ]}
                    >
                      <View
                        style={{
                          paddingVertical: 6,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text style={styles.childName}>
                          {child.shortName} ({child.ageString})
                        </Text>
                        <BouncingChevronRight
                          isNotAnimated
                          size={20}
                          color="white"
                          isPressed={pressedCardIndex === child.id}
                        />
                      </View>
                    </Card>
                  </TouchableRipple>
                ))}
                {lagging.length <= 0 && <Text>-</Text>}
              </View>

              <View
                style={{
                  marginVertical: 6,
                }}
              >
                <IconWrapper
                  icon={'account-question'}
                  color={colors.orange}
                  bgColor={colors.veryLightOrange}
                  width={35}
                  height={35}
                  iconSize={20}
                >
                  <Text style={{ marginTop: 5 }}>
                    Děti, které nemají vyplněno
                  </Text>
                  <CircularProgress
                    delay={100}
                    percent={reversePercentageAverageFillRate}
                    reverse
                    style={{
                      position: 'absolute',
                      top: -10,
                      right: 0,
                      backgroundColor: colors.white,
                      marginTop: 5,
                    }}
                  />
                </IconWrapper>
              </View>
              <View>
                {notFilledOut?.map((child, idx: number) => (
                  <TouchableRipple
                    key={child.id}
                    rippleColor={
                      child.gender === 'M'
                        ? colors.veryLightBlue
                        : colors.veryLightDarkRed
                    }
                    onPress={() =>
                      onCardPress(child.id, true, () =>
                        navigation.push('ChildCategory', {
                          childId: child.id,
                          categoryId,
                        }),
                      )
                    }
                  >
                    <Card
                      style={[
                        styles.item,
                        child.gender === 'M' && styles.boy,
                        child.gender === 'F' && styles.girl,
                      ]}
                    >
                      <View
                        style={{
                          paddingVertical: 6,
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Text style={styles.childName}>
                          {child.shortName} ({child.ageString})
                        </Text>
                        <CircularProgress
                          delay={200}
                          duration={800}
                          size={48}
                          textColor={colors.lightGrey}
                          percent={Math.round(
                            child.averageFillRateCategory * 100,
                          )}
                        />
                        <BouncingChevronRight
                          isNotAnimated
                          size={20}
                          color="white"
                          isPressed={pressedCardIndex === child.id}
                        />
                      </View>
                    </Card>
                  </TouchableRipple>
                ))}

                {notFilledOut?.length <= 0 && <Text>-</Text>}
              </View>
            </Card.Content>
          </Card>
        );
      },
    [categories, categoryStats, onCardPress, pressedCardIndex, navigation],
  );

  return (
    <ScrollView
      contentContainerStyle={[
        styles.scrollViewContent,
        isDesktop && styles.desktopScrollViewContent,
      ]}
    >
      <View style={[styles.container, isDesktop && desktopContainerStyle]}>
        <RollOptions
          setShowData={setShowExtendedData}
          showData={showExtendedData}
          name={'Vyfiltrovat vyplněnost'}
        />
        {showExtendedData && (
          <View style={styles.dropdownContainer}>
            <DropDown
              label="Vyfiltrovat podle vyplněnosti dítěte"
              mode="outlined"
              visible={showDropdown}
              showDropDown={() => setShowDropdown(true)}
              onDismiss={() => setShowDropdown(false)}
              value={selectedChildPercentageFillRate}
              setValue={(value) => {
                setShowDropdown(false);
                setSelectedChildPercentageFillRate(value);
                showToast(
                  'Vybraná procenta byla uložena',
                  'Vyfiltrováno podle vyplněnosti dětí',
                  'success',
                  6000,
                );
              }}
              list={percentageOptions}
              dropDownStyle={{ paddingHorizontal: 10, marginLeft: 8 }}
              dropDownItemSelectedStyle={{
                backgroundColor: colors.veryLightDarkRed,
              }}
              inputProps={{
                style: {
                  backgroundColor: 'white',
                  marginVertical: 8,
                  marginHorizontal: 10,
                },
                right: (
                  <TextInput.Icon
                    icon={showDropdown ? 'chevron-down' : 'chevron-up'}
                  />
                ),

                dense: true,
              }}
            />
          </View>
        )}

        <FlatList
          contentContainerStyle={styles.flatListContent}
          ref={flatListRef}
          data={[0].concat(categoryOrder)}
          keyExtractor={(id) => id.toString()}
          renderItem={renderItem}
          onScroll={handleScroll}
          scrollEventThrottle={1}
          refreshControl={<RefreshUserData />}
        />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scrollViewContent: {
    flexGrow: 1,
  },
  desktopScrollViewContent: {
    alignItems: 'center',
  },
  container: {
    flex: 1,
    width: '100%',
  },
  flatListContent: {
    padding: 10,
  },
  dropdownContainer: {
    marginBottom: 10,
  },
  card: {
    borderColor: colors.lightOrange,
    borderWidth: 1,
    paddingBottom: 6,
    position: 'relative',
  },
  overview: {
    flexDirection: 'row',
    justifyContent: 'center',
    gap: 8,
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingVertical: 8,
    marginTop: isWeb ? 42 : 12,
    marginRight: 32,
    marginBottom: 12,
  },
  downloadButton: {
    position: 'absolute',
    top: -4,
    right: -5,
    zIndex: 1,
  },
  item: {
    backgroundColor: colors.green,
    marginVertical: 4,
    paddingHorizontal: 8,
    paddingVertical: 3,
    width: '100%',
  },
  itemContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  childName: {
    paddingVertical: 7,
    paddingHorizontal: 2,
    fontWeight: 'bold',
    color: 'white',
  },
  boy: {
    backgroundColor: colors.blue,
  },
  girl: {
    backgroundColor: colors.red,
  },
  classroomLabel: {
    fontSize: 20,
    color: colors.darkRed,
    fontWeight: 'bold',
    marginVertical: 4,
  },
});
