import React from 'react';
import { ActivityIndicator, FlatList, StyleSheet, View } from 'react-native';
import { Caption } from 'react-native-paper';
import { AssessmentsProps } from '../lib/navigation';
import { useRecoilValue } from 'recoil';
import {
  assessmentTypesState,
  categoriesState,
  childrenState,
  latestAssessmentsState,
  subcategoriesState,
  tasksState,
  usersState,
} from '../store';
import { Components } from '../server';
import { format } from 'date-fns';
import { RefreshUserData } from '../components/RefreshUserData';
import { icons } from '../components/icons';
import { colors } from '../theme';
import NoResults from '../components/ui/NoResults';
import { removeDiacritics } from '../lib/remove-diacritics';
import AssessmentCard from '../components/assessments/AssessmentCard';
import CustomSearchBar from '../components/ui/CustomSearchBar';
import { Background } from '../components/ui/Background';
import WebContainer from '../components/ui/WebContainer';

import { isWeb } from '../lib/is-web';

export const AssessmentsScreen = React.memo(function AssessmentsScreen({
  navigation,
}: AssessmentsProps) {
  const children = useRecoilValue(childrenState);
  const users = useRecoilValue(usersState);
  const assessments = useRecoilValue(latestAssessmentsState).slice(0, 80);

  const assessmentTypes = useRecoilValue(assessmentTypesState);
  const categories = useRecoilValue(categoriesState);
  const subcategories = useRecoilValue(subcategoriesState);
  const tasks = useRecoilValue(tasksState);

  const searchRef = React.useRef(null);
  const [searchQuery, setSearchQuery] = React.useState('');
  const onChangeSearch = (query: string) => setSearchQuery(query);

  const filteredAssessments = React.useMemo(() => {
    return assessments.filter((assessment) => {
      const assessmentSearch = removeDiacritics(
        assessment?.note?.toLowerCase() || '',
      );
      const dateSearch = format(
        new Date(assessment.date_of_assessment),
        'd. M. yyyy',
      );
      const category =
        categories[
          subcategories[tasks[assessment.task]?.subcategory || 0]
            ?.parent_category || 0
        ];

      const tasksSearch = removeDiacritics(
        tasks[assessment.task]?.task_description || '',
      );
      const child = children[assessment.child];
      const childSearch = child?.first_name
        ? removeDiacritics(`${child?.first_name} ${child?.last_name}`)
        : '';
      const user = users[assessment.assessed_by || 0];
      const userSearch = user?.first_name
        ? removeDiacritics(`${user?.first_name} ${user?.last_name}`)
        : user?.email;

      const searchQueryWithoutDiacritics = removeDiacritics(
        searchQuery.toLowerCase(),
      );

      return (
        userSearch?.includes(searchQueryWithoutDiacritics) ||
        tasksSearch?.includes(searchQueryWithoutDiacritics) ||
        category?.label?.toLowerCase().includes(searchQueryWithoutDiacritics) ||
        childSearch?.includes(searchQueryWithoutDiacritics) ||
        assessmentSearch?.includes(searchQueryWithoutDiacritics) ||
        dateSearch.includes(searchQueryWithoutDiacritics)
      );
    });
  }, [assessments, categories, children, searchQuery, tasks, users]);

  const renderItem = ({
    item,
    index,
  }: {
    item: Components.Schemas.Assessment;
    index: number;
  }) => {
    const user = users[item?.assessed_by || 0];
    const child = children[item.child];
    const task = tasks[item.task];
    const assessmentType = assessmentTypes[task?.assessment_type || 0];
    const option = assessmentType?.options?.find((x) => x.id === item.option);
    const Icon =
      icons[
        categories[subcategories[task?.subcategory || 0]?.parent_category || 0]
          ?.label || ''
      ];
    const isMaleGender = child?.gender === 'M';

    return (
      <WebContainer>
        <AssessmentCard
          item={item}
          user={user}
          child={child}
          task={task}
          direction={index % 2 === 0 ? 'left' : 'right'}
          onPressEdit={() =>
            navigation.navigate('EditAssessment', {
              assessmentId: item?.id ?? 0,
            })
          }
          onPressChild={() =>
            navigation.navigate('Child', { childId: child?.id ?? 0 })
          }
          option={option}
          Icon={Icon}
          isMaleGender={isMaleGender}
        />
      </WebContainer>
    );
  };

  return (
    <Background>
      <View style={styles.container}>
        {!assessments?.length && (
          <View style={styles.emptyState}>
            <Caption>
              Přidejte hodnocení kliknutím na {'"+"'} u třídy nebo dítěte
            </Caption>
          </View>
        )}
        <CustomSearchBar
          searchQuery={searchQuery}
          onChange={onChangeSearch}
          ref={searchRef}
        />
        {searchQuery && !filteredAssessments.length && <NoResults />}

        <FlatList
          contentContainerStyle={{
            paddingVertical: isWeb ? 45 : 0,
            paddingHorizontal: isWeb ? 0 : 10,
          }}
          refreshControl={<RefreshUserData />}
          data={filteredAssessments}
          keyExtractor={({ id }) => id.toString()}
          renderItem={({ item, index }) =>
            item ? renderItem({ item, index }) : <ActivityIndicator />
          }
          ListFooterComponent={<View style={{ height: 100 }} />}
        />
      </View>
    </Background>
  );
});

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },

  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  assessment: {
    fontSize: 16,
    backgroundColor: 'white',
    padding: 6,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  content: {
    flex: 1,
    gap: 10,
  },
  child: {
    fontSize: 16,
    marginLeft: 8,
  },

  icon: {
    width: 40,
    height: 40,
    alignSelf: 'flex-end',
  },
  emptyState: {
    margin: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noResults: {
    fontSize: 16,
    color: colors.darkRed,
  },
});
