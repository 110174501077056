import { ViewStyle } from 'react-native';
import { useWindowDimensions } from 'react-native';
import { isWeb } from '../lib/is-web';

export const useDesktopStyles = (customWidth = 800) => {
  const { width } = useWindowDimensions();
  const isDesktop = isWeb && width >= 904;

  const desktopContainerStyle: ViewStyle = {
    width: isDesktop ? '100%' : '95%',
    maxWidth: isDesktop ? customWidth : '100%',
    marginHorizontal: isDesktop ? 'auto' : '2.5%',
    flex: 1,
  };

  return { isDesktop, desktopContainerStyle };
};
