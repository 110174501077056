import React from 'react';
import { FlatList, View, Text, StyleSheet } from 'react-native';
import { ClassIDContext } from '../../lib/contexts';
import { useIsFocused } from '@react-navigation/native';
import { ClassChildrenProps } from '../../lib/navigation';
import { useRecoilValue } from 'recoil';
import { useMultiFABScroll } from '../multifab/MultiFABContext';
import { ChildType, classChildrenState, classState } from '../../store';
import { RefreshUserData } from '../RefreshUserData';
import { ChildListItem } from './ChildListItem';
import { colors, theme } from '../../theme';
import NoResults from '../ui/NoResults';
import { removeDiacritics } from '../../lib/remove-diacritics';
import ChildInfo from './ChildInfo';
import CustomSearchBar from '../ui/CustomSearchBar';
import FadeInFromLeftOrRight from '../animations/FadeInFromLeftOrRightAnimation';
import { Background } from '../ui/Background';
import { IconButton } from 'react-native-paper';
import { Dimensions } from 'react-native';
import { isWeb } from '../../lib/is-web';

export function ChildList({ navigation }: ClassChildrenProps) {
  const classId = React.useContext(ClassIDContext);
  const children = useRecoilValue(classChildrenState(classId));
  const classroom = useRecoilValue(classState(classId));
  const { setStatus } = useMultiFABScroll();
  const [show, setShow] = React.useState(true);

  const isFocused = useIsFocused();
  const [mode, setMode] = React.useState<'view' | 'select'>('view');
  const [selected, setSelected] = React.useState<number[]>([]);

  const searchRef = React.useRef<any | undefined>(null);
  const [searchQuery, setSearchQuery] = React.useState('');
  const onChangeSearch = (query: string) => setSearchQuery(query);

  const filteredChildren = children?.filter((child) => {
    const childSearch = removeDiacritics(child.first_name + child.last_name);
    return childSearch.toLowerCase().includes(searchQuery.toLowerCase());
  });

  React.useEffect(() => {
    if (isFocused) {
      setSelected([]);
      setMode('view');
    }
  }, [setSelected, setMode, isFocused]);

  React.useEffect(() => {
    if (isFocused) {
      setStatus({
        multi: mode === 'select',
        initial: { classId, childIds: selected },
      });
    }
  }, [setStatus, isFocused, mode, classId, selected]);

  React.useEffect(() => {
    if (selected.length === 0 && mode === 'select') {
      setMode('view');
    } else if (selected.length > 0 && mode === 'view') {
      setMode('select');
    }
  }, [selected, mode, setMode]);

  const onPressCheck = React.useCallback(
    (childId: number) => {
      setSelected((selected) => {
        return selected.includes(childId)
          ? selected.filter((x) => x !== childId)
          : [...selected, childId];
      });
    },
    [setSelected],
  );

  const onPress = React.useCallback(
    (childId: number) => {
      if (mode === 'view') {
        navigation.push('Child', { childId });
      } else {
        onPressCheck(childId);
      }
    },
    [navigation, onPressCheck, mode],
  );

  const renderItem = ({ item, index }: { item: ChildType; index: number }) => (
    <>
      <ChildListItem
        selected={selected.includes(item.id)}
        {...{ item, onPress, onPressCheck }}
      />
    </>
  );

  return (
    <Background>
      <View style={styles.topContent}>
        {!show ? (
          <FadeInFromLeftOrRight direction="left">
            <Text
              style={[
                styles.classroomLabel,
                { position: 'absolute', top: 4, left: 8 },
              ]}
            >
              {classroom?.label || 'Třída'}
            </Text>
          </FadeInFromLeftOrRight>
        ) : null}
        <IconButton
          style={{
            backgroundColor: colors.white,
            marginLeft: 'auto',
            position: show ? 'absolute' : 'relative',
            zIndex: 10,
            top: 2,
            right: 2,
            marginVertical: 10,
          }}
          iconColor={theme.colors.primary}
          icon={show ? 'chevron-up' : 'chevron-down'}
          onPress={() => setShow((prev) => !prev)}
        />

        {show && (
          <>
            <View
              style={{
                flexDirection: 'column',
                paddingVertical: 3,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FadeInFromLeftOrRight direction="left">
                <Text style={styles.classroomLabel}>
                  {classroom?.label || 'Třída'}
                </Text>
              </FadeInFromLeftOrRight>
              <View
                style={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <ChildInfo length={children.length} />
              </View>
            </View>

            <CustomSearchBar
              searchQuery={searchQuery}
              onChange={onChangeSearch}
              ref={searchRef}
              delay={500}
            />
          </>
        )}
      </View>

      {searchQuery && !filteredChildren.length && <NoResults />}

      <FlatList
        style={styles.flatList}
        initialNumToRender={40}
        data={filteredChildren}
        // contentContainerStyle={{ paddingBottom: 20 }}
        refreshControl={<RefreshUserData />}
        keyExtractor={({ id }) => id.toString()}
        ListFooterComponent={
          show ? (
            <View style={{ height: 200 }} />
          ) : (
            <View style={{ height: 50 }} />
          )
        }
        numColumns={2}
        renderItem={renderItem}
      />
    </Background>
  );
}
const deviceWidth = Dimensions.get('window').width;
const styles = StyleSheet.create({
  scrollViewContent: {
    flexGrow: 1,
  },
  desktopScrollViewContent: {
    alignItems: 'center',
  },
  flatListContent: {
    alignItems: 'flex-start',
    paddingVertical: 6,
  },
  topContent: {
    width: '100%',
    paddingBottom: 10,
    paddingHorizontal: 8,
  },
  flatList: {
    width: '100%',
    paddingHorizontal: isWeb ? 0 : 8,
    flex: 1,
  },
  container: {
    flex: 1,
    width: '100%',
  },
  classroomLabel: {
    fontSize: 18,
    width: isWeb ? '100%' : deviceWidth - 20,
    color: theme.colors.primary,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 8,
  },
});
