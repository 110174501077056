import React from 'react';
import { TouchableOpacity, StyleSheet, View, Linking } from 'react-native';

import { Logo } from '../components/ui/Logo';
import { Button } from '../components/ui/Button';
import { Scroller } from '../components/ui/Scroller';
import { colors, theme } from '../theme';
import { LoginProps } from '../lib/navigation';
import { useAuth } from '../hooks/use-auth';
import { useApi } from '../hooks/use-api';
import { Icon, Text, TextInput } from 'react-native-paper';
import { useNetInfo } from '@react-native-community/netinfo';
import { useFetch } from '../hooks/use-fetch';
import { AxiosError } from 'axios';
import SocialIconLink from '../components/ui/SocialIconLink';
import IconWrapper from '../components/ui/IconWrapper';
import { showToast } from '../lib/toast';
import * as Application from 'expo-application';
import { BubbleScaleUpAnimation } from '../components/animations/BubbleScaleUpAnimation';
import { FadeInFromTopAnimation } from '../components/animations/FadeInFromTopAnimation';
import { BounceAnimationInfinite } from '../components/animations/BounceAnimationInfinite';
import { PulsingAnimationInfinite } from '../components/animations/PulsingAnimationInfinite';
import { BackgroundKeyboard } from '../components/ui/BackgroundKeyboard';

import * as SecureStore from 'expo-secure-store';
import { isWeb } from '../lib/is-web';

export const LoginScreen = React.memo(function LoginScreen({
  navigation,
}: LoginProps) {
  const [email, setEmail] = React.useState({ value: '', error: '' });
  const [password, setPassword] = React.useState({ value: '', error: '' });
  const [showPassword, setShowPassword] = React.useState(false);
  const [loggingIn, setLoggingIn] = React.useState(false);
  const { logIn } = useAuth();
  const { publicAxios } = useApi();
  const { fetchAll, inProgress, total } = useFetch();
  const { isConnected } = useNetInfo();

  const emailContact = 'klokanovaapka@luzanky.cz';
  const phoneContact = '+420724520122';

  const isFilled = React.useMemo(() => {
    return email.value.length > 0 && password.value.length > 0;
  }, [email, password]);

  React.useEffect(() => {
    if (!isWeb) {
      const getSavedCredentials = async () => {
        try {
          const email = await SecureStore.getItemAsync('userEmail');
          if (email) {
            setEmail({ value: email ?? '', error: '' });
          }
        } catch (error) {
          console.error('Error loading auth state:', error);
        }
      };

      getSavedCredentials();
    }
  }, []);

  const onLoginPressed = React.useCallback(async () => {
    const emailError =
      !email.value || email.value.length <= 0 ? 'Vyplňte údaje' : '';
    const passwordError =
      !password.value || password.value.length <= 0 ? 'Vyplňte údaje' : '';
    if (emailError || passwordError) {
      setEmail({ ...email, error: emailError });
      setPassword({
        ...password,
        error: passwordError,
      });
      showToast(
        emailError || passwordError,
        'Vyplňte prosím všechny údaje (email i heslo)',
        'error',
        5000,
      );
      return;
    }

    if (!isConnected) {
      setPassword({
        ...password,
        error: 'Nejste připojeni k internetu. Připojte se a zkuste to znovu',
      });
      showToast(
        'Nejste připojeni k internetu',
        'Připojte se a zkuste to znovu',
        'error',
        4000,
      );
      return;
    }

    try {
      setLoggingIn(true);
      const response = await publicAxios.post<{
        access: string;
        refresh: string;
      }>('/api/token/', {
        email: email.value,
        password: password.value,
      });
      logIn({ email: email.value, ...response.data, password: password.value });
      await fetchAll();
      showToast('Vítejte', 'Přihlášení proběhlo úspěšně', 'success', 3000);
    } catch (e) {
      setLoggingIn(false);
      let error = e instanceof Error ? e.message : String(e);
      if (
        e instanceof AxiosError &&
        (e.response?.data as Record<string, any>)?.detail ===
          'No active account found with the given credentials'
      ) {
        error = 'Zadaná kombinace e-mailu a hesla není platná';
      }
      showToast('Chyba přihlášení', error, 'error', 4000);
      // alertCross('Chyba', error);
    }
  }, [fetchAll, email, password, logIn, publicAxios, isConnected]);

  const onDemoPressed = React.useCallback(async () => {
    if (!isConnected) {
      setPassword((x) => ({
        ...x,
        error: 'Nejste připojeni k internetu. Připojte se a zkuste to znovu',
      }));
      showToast(
        'Nejste připojeni k internetu',
        'Připojte se a zkuste to znovu',
        'error',
        4000,
      );

      return;
    }

    try {
      setLoggingIn(true);
      const response = await publicAxios.post<{
        access: string;
        refresh: string;
      }>('/api/token/', {
        email: 'headmaster1@mail.com',
        password: 'password',
      });
      logIn({
        email: 'headmaster1@mail.com',
        ...response.data,
        demo: true,
        password: '',
      });

      await fetchAll();
      showToast(
        'Vítejte v demoverzi',
        'Prohlédněte si aplikaci a vyzkoušejte si ji.',
        'success',
        4000,
      );
    } catch (e) {
      setLoggingIn(false);
      showToast(
        'Chyba přihlášení',
        e instanceof Error ? e.message : String(e),
        'error',
        4000,
      );
    }
  }, [fetchAll, logIn, publicAxios, isConnected]);

  return (
    <BackgroundKeyboard>
      <Scroller
        style={{
          alignSelf: 'center',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 6,
        }}
      >
        <BubbleScaleUpAnimation delay={0} duration={600}>
          <View style={{ marginLeft: 4 }}>
            <Logo />
          </View>
        </BubbleScaleUpAnimation>

        <FadeInFromTopAnimation duration={350} delay={50}>
          <View
            style={{
              alignItems: 'center',
              maxWidth: 450,
              flexDirection: 'row',
            }}
          >
            <Text
              style={{
                marginVertical: 6,
                marginBottom: 14,
                textAlign: 'center',
              }}
            >
              Vyzkoušejte si aplikaci kliknutím na{'\xa0'}
              {'"Přihlásit\xa0do\xa0demoverze"'}
            </Text>
            <BounceAnimationInfinite delay={0}>
              <Icon
                source={'arrow-down-bold-circle'}
                size={24}
                color={colors.orange}
              />
            </BounceAnimationInfinite>
          </View>
        </FadeInFromTopAnimation>
        <FadeInFromTopAnimation duration={350} delay={100}>
          <View>
            <IconWrapper icon="email">
              <TextInput
                mode="outlined"
                style={styles.input}
                underlineColorAndroid={'transparent'}
                label="E-mail"
                returnKeyType="next"
                value={email.value}
                onChangeText={(text) => setEmail({ value: text, error: '' })}
                error={!!email.error}
                autoCapitalize="none"
                autoComplete="email"
                textContentType="emailAddress"
                keyboardType="email-address"
                disabled={loggingIn}
              />
            </IconWrapper>

            <IconWrapper icon="lock">
              <TextInput
                mode="outlined"
                style={styles.input}
                label="Heslo"
                returnKeyType="done"
                value={password.value}
                onChangeText={(text) => setPassword({ value: text, error: '' })}
                error={!!password.error}
                autoComplete="password"
                secureTextEntry={!showPassword}
                disabled={loggingIn}
                right={
                  <TextInput.Icon
                    style={{ marginTop: 9 }}
                    color={showPassword ? theme.colors.primary : colors.grey}
                    icon={showPassword ? 'eye-outline' : 'eye-off-outline'}
                    onPress={() => setShowPassword((x) => !x)}
                  />
                }
              />
            </IconWrapper>
          </View>

          <View style={styles.forgotPassword}>
            <TouchableOpacity onPress={() => navigation.push('ForgotPassword')}>
              <Text style={styles.link}>Zapomněli jste heslo?</Text>
            </TouchableOpacity>
          </View>
        </FadeInFromTopAnimation>
        <FadeInFromTopAnimation duration={350} delay={250}>
          <View style={{ alignItems: 'center', gap: 0, marginTop: 8 }}>
            <PulsingAnimationInfinite isFilled={isFilled} delay={0}>
              <Button
                style={{ width: 250 }}
                mode="contained"
                uppercase
                onPress={onLoginPressed}
                disabled={loggingIn}
              >
                {!loggingIn
                  ? 'Přihlásit se'
                  : inProgress === 0
                    ? 'Přihlašuji se...'
                    : `Stahuji data... ${Math.round(
                        ((total - inProgress) / total) * 100,
                      )}%`}
              </Button>
            </PulsingAnimationInfinite>

            <Button
              style={{ width: '100%' }}
              uppercase
              labelStyle={{ color: theme.colors.primary }}
              mode="outlined"
              onPress={onDemoPressed}
              disabled={loggingIn}
            >
              Přihlásit do demoverze{' '}
            </Button>
          </View>
        </FadeInFromTopAnimation>

        <View style={{ marginVertical: 6, alignItems: 'center' }}>
          <FadeInFromTopAnimation duration={350} delay={700}>
            <View style={{ alignItems: 'center' }}>
              <Text>Líbí se vám aplikace?</Text>
              {/* <Text>
            Chcete ji používat i{'\xa0'}ve{'\xa0'}vaší{'\xa0'}školce?
          </Text> */}
              <Text>Kontaktujte nás.</Text>
            </View>
          </FadeInFromTopAnimation>

          <View
            style={{
              flexDirection: 'row',
              gap: 10,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <BubbleScaleUpAnimation delay={1000}>
              <SocialIconLink
                icon="facebook"
                onPress={() =>
                  Linking.openURL(
                    'https://www.facebook.com/groups/1860982417493609/',
                  )
                }
              />
            </BubbleScaleUpAnimation>

            <BubbleScaleUpAnimation delay={1150}>
              <SocialIconLink
                icon="web"
                onPress={() =>
                  Linking.openURL('https://klokanovyskolky.luzanky.cz/')
                }
                top={18}
              />
            </BubbleScaleUpAnimation>
            <BubbleScaleUpAnimation delay={1300}>
              <SocialIconLink
                icon="phone"
                onPress={() =>
                  Linking.openURL(`tel:${phoneContact?.replace(' ', '-')}`)
                }
                top={18}
              />
            </BubbleScaleUpAnimation>
            <BubbleScaleUpAnimation delay={1450}>
              <SocialIconLink
                icon="email"
                onPress={() => Linking.openURL(`mailto://${emailContact}`)}
              />
            </BubbleScaleUpAnimation>
          </View>
        </View>
        <Text
          style={{
            position: 'absolute',
            bottom: 6,
            right: -6,
            color: colors.lightGrey2,
          }}
        >
          {Application.nativeApplicationVersion}
        </Text>
      </Scroller>
    </BackgroundKeyboard>
  );
});

const styles = StyleSheet.create({
  forgotPassword: {
    width: 280,
    alignItems: 'flex-end',
    marginBottom: 8,
  },
  link: {
    fontWeight: 'bold',
    color: theme.colors.primary,
    fontSize: 15,
  },
  input: {
    position: 'relative',
    width: 240,
    marginBottom: 5,
    backgroundColor: 'white',
  },
});
